/*
    Přidání štítku
*/
import React,{ Component, Fragment } from 'react';
import {Mutation,Query} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import Modal from 'react-modal';
import {ADD_EDIT_MARKETING} from '../Queries/marketing.js';
import { withApollo } from 'react-apollo'; 
import MarketingLib from '../Library/marketing';
import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper';
import { Editor } from '@tinymce/tinymce-react';


const INITIAL_STATE = {
    apolloError:"",
    selectedMarketingID:null,
    mainPhoto:"",
    formData:{
        selectedLang:"cz",
        marketingID:0,
        showInBlog:1,
        showInAllArticles:0,
        photo:null,
        langs:[],
    },
    allLanguageMutations:[]
}

class AddEditMarketing extends Component{

    constructor(props){
        super(props);
        this.state = INITIAL_STATE;
    }

    componentDidMount(){

        this.marketingLib = new MarketingLib(this); 
        this.marketingLib.getData();
        
    }
    
    render(){

        const {apolloError,mainPhoto,allLanguageMutations} = this.state;
        var {selectedMarketingID,listVariables} = this.props;


        return(

            <Mutation 
                mutation={ADD_EDIT_MARKETING}
                update = {async (cache, response) => {

                    this.marketingLib.updateList(cache,response,selectedMarketingID,listVariables);
                    
                    let notify = new Notification();

                    if(selectedMarketingID && selectedMarketingID != 0) notify.setNotification(cache,'Úspěšně uloženo',true,false);
                    else notify.setNotification(cache,'Úspěšně přidáno',true,false);

                    this.props.openCloseModal(false);

                }}
                
            >
            {
                (addMarketing,{data,loading,error}) => {

                    if(error){
                        const helper = new Helper(); 
                        error = helper.getApolloErrorText(error);
                    }

                    const {title,text,photo,selectedLang,showInBlog,showInAllArticles} = this.state.formData;

                    return(
                        <Modal
                            className="Modal__Bootstrap modal-dialog modal-lg"
                            closeTimeoutMS={150}
                            isOpen={true}
                            onRequestClose={() => this.props.openCloseModal(false)}
                        >
                            <div className="modal-content">
                            <div className="modal-header d-flex align-items-center">
                                    <h4 className="modal-title">{(selectedMarketingID && selectedMarketingID != 0 ? "Úprava marketingu" : "Přidání marketingu")}</h4>
                                    <div className="ml-auto">
                                        <select className="form-control" name="selectedLang" onChange={(e) => this.marketingLib.formHandle(e)}>
                                            {allLanguageMutations && allLanguageMutations.map((item,index) => (
                                                <option key={index} value={item.suffix}> {item.suffix} </option>
                                            ))}
                                        </select>
                                    </div>
                                    <button type="button" className="close ml-3" onClick={() => this.props.openCloseModal(false)}>                                    
                                        <span aria-hidden="true">x</span>
                                        <span className="sr-only">Close</span>
                                    </button>
                                </div>
                                <div className="modal-body">

                                    {error || apolloError ?
                                        <Error text={error || apolloError} />
                                    :
                                    <div>
                                        
                                        {!loading ?
                                            <Fragment>                                                               
                                                <div className="form-group input-group">
                                                    <div className="custom-file">
                                                        
                                                        <input
                                                            type="file"
                                                            className="custom-file-input"
                                                            id="customFile"
                                                            name="file"
                                                            required
                                                            multiple
                                                            onChange={(e) => this.marketingLib.openImage(e)}
                                                        />
                                                        <label className="custom-file-label" htmlFor="customFile">Vyberte fotku</label>
                                                    </div>
                                                </div>
                                                
                                                {mainPhoto &&
                                                    <div className="row">
                                                        <div className="col-12 col-sm-4">
                                                            <img className = "maxwidth-100" src={mainPhoto} />
                                                        </div>
                                                        <div className="col-12 col-sm-8">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-5">
                                                                    <div className="form-group">
                                                                        <label className="input-label">Nadpis marketingu  ({selectedLang})</label>
                                                                        <div className="form-group">
                                                                            <input className="form-control" type="text" name="title" value={this.marketingLib.getLangValue("title")} onChange={(e) => this.marketingLib.formLangHandle(e)} />
                                                                        </div>
                                                                    </div>  
                                                                </div> 
                                                                <div className="col-12 col-sm-3">
                                                                    <div className="form-group">
                                                                        <label className="input-label">Zobrazit v blogu</label>
                                                                        <div className="form-group">
                                                                            <div className="form-check form-check-inline">
                                                                                <input className="form-check-input" type="radio" name="showInBlog" id="showInBlog1" value="1" checked={(showInBlog == 1 ?  true : false)} onChange={(e) => this.marketingLib.formHandle(e)} />
                                                                                <label className="form-check-label" htmlFor="showInBlog1">
                                                                                    Ano
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check form-check-inline">
                                                                                <input className="form-check-input" type="radio" name="showInBlog" id="showInBlog2" value="0" checked={(showInBlog == 0 ?  true : false)} onChange={(e) => this.marketingLib.formHandle(e)} />
                                                                                <label className="form-check-label" htmlFor="showInBlog2">
                                                                                    Ne
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div> 
                                                                </div>
                                                                <div className="col-12 col-sm-4">
                                                                    <div className="form-group">
                                                                        <label className="input-label">Zobrazit ve všech článcích</label>
                                                                        <div className="form-group">
                                                                            <div className="form-check form-check-inline">
                                                                                <input className="form-check-input" type="radio" name="showInAllArticles" id="showInAllArticles1" value="1" checked={(showInAllArticles == 1 ?  true : false)} onChange={(e) => this.marketingLib.formHandle(e)} />
                                                                                <label className="form-check-label" htmlFor="showInAllArticles1">
                                                                                    Ano
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check form-check-inline">
                                                                                <input className="form-check-input" type="radio" name="showInAllArticles" id="showInAllArticles2" value="0" checked={(showInAllArticles == 0 ?  true : false)} onChange={(e) => this.marketingLib.formHandle(e)} />
                                                                                <label className="form-check-label" htmlFor="showInAllArticles2">
                                                                                    Ne
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div> 
                                                                </div>     
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label className="input-label">Text tlačítka  ({selectedLang})</label>
                                                                        <div className="form-group">
                                                                            <input className="form-control" type="text" name="buttonText" value={this.marketingLib.getLangValue("buttonText")} onChange={(e) => this.marketingLib.formLangHandle(e)} />
                                                                        </div>
                                                                    </div>  
                                                                </div>  
                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label className="input-label">URL tlačítka  ({selectedLang})</label>
                                                                        <div className="form-group">
                                                                            <input className="form-control" type="text" name="buttonUrl" value={this.marketingLib.getLangValue("buttonUrl")} onChange={(e) => this.marketingLib.formLangHandle(e)} />
                                                                        </div>
                                                                    </div> 
                                                                </div> 
                                                            </div>                                                        
                                                        </div>
                                                        <div className="col-12">
                                                            <label className="input-label">Text marketingu ({selectedLang})</label>
                                                            <div className="form-group">
                                                                <Editor
                                                                    name="text"
                                                                    apiKey = '9z3phhnyq7jkeeqztql1euovfotvpd2gdduygtdpk14wu4ij'
                                                                    value={this.marketingLib.getLangValue("text")}
                                                                    init={{
                                                                        plugins: 'link code paste emoticons',
                                                                        toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | formatselect | link | blockquote | emoticons | code',
                                                                        paste_as_text: true,
                                                                        language_url : '/langs/cs.js',
                                                                        language: "cs",
                                                                        link_assume_external_targets: true,
                                                                    }}
                                                                    onEditorChange={(content) => this.marketingLib.formLangHandleEditor(content,"text")}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
  
                                            </Fragment>

                                        :
                                        <Loading />  
                                        }  

                                    </div>
                                    }
               
                                </div>
                                <div className="modal-footer"> 
                                
                                    <button type="button" className="btn btn-primary flex-fill" onClick={() => this.marketingLib.addMarketing(addMarketing)}>{(selectedMarketingID && selectedMarketingID != 0 ? "Upavit" : "Přidat")}</button>
                                    <button type="button" className="btn btn-danger" onClick={() => this.props.openCloseModal(false)}>Zavřít</button>
                                </div>
                            </div>
                        </Modal>
                    );

                }
            }
            </Mutation>
        
        );

    }

}

export default withApollo(AddEditMarketing);